jQuery(document).ready(function($) {
    $('li.menu-item-has-children > a').on('click',function(event){
        event.preventDefault()

        $(this).parent().find('ul').first().toggleClass('active');

        $(this).parent().siblings().find('ul').removeClass('active');

        //Hide menu when clicked outside
        $(this).parent().find('ul').mouseleave(function(){
        var thisUI = $(this);
        $('html').click(function(){
            thisUI.removeClass('active');
            $('html').unbind('click');
        });
        });
    });

    var headerHeight = $('header').height();
	$(window).scroll(function(){
		if ($(window).scrollTop() >= 20) {
			$('body').css('padding-top', headerHeight);

			$('.site-header').addClass('site-header--fixed');
		} else {
			$('body').css('padding-top', 0);
			
			$('.site-header').removeClass('site-header--fixed');
		}
	});
});