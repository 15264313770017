import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){
		new Swiper('.event-swiper', {
			modules: [Navigation],
			slidesPerView: "1",
			centeredSlides: true,
			spaceBetween: 15,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				1024: {
				slidesPerView: "3",
					spaceBetween: 30,
				},
			},
		});

	}

});
